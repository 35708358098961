module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-W2NXLY42RH","cookieName":"gatsby-gdpr-google-analytics","anonymize":true},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The Oast Osteopathy","short_name":"The Oast Osteopathy","start_url":"/","background_color":"#BFE0CC","theme_color":"#BFE0CC","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d10e27f4e98a25a96e83986bedf9d6ce"},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"accessToken":"MC5YSFdTMlJFQUFMZ3NzTTUx.77-9Ae-_ve-_vUkG77-977-9CX4HKe-_ve-_ve-_ve-_vWEO77-9bTDvv71L77-977-9MSEjIu-_ve-_vU8","lang":"en-gb","repositoryName":"oast-osteopathy","routes":[{"type":"page","uid":"index","path":"/:lang?/"},{"type":"page","path":"/:lang?/:uid/"},{"type":"blogpost","path":"/:lang?/blog/:uid/"},{"type":"team","path":"/:lang?/team/:uid/"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
