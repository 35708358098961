import * as React from 'react'
import { Link, navigate } from 'gatsby'
import { Auth0Provider } from '@auth0/auth0-react'
import { PrismicProvider } from '@prismicio/react'
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews'

// Utils
import { repositoryConfigs } from './src/utils/prismicPreviews'
import { linkResolver } from './src/utils/linkResolver'

import './src/styles/default.css'
import '@fontsource/merriweather'
import '@fontsource/lato'

const onRedirectCallback = (appState) => {
  navigate(appState?.returnTo || '/', { replace: true })
}

export const wrapRootElement = ({ element }) => (
  <Auth0Provider
    domain={process.env.GATSBY_AUTH0_DOMAIN}
    clientId={process.env.GATSBY_AUTH0_CLIENTID}
    authorizationParams={{
      redirect_uri: window.location.origin
    }}
    onRedirectCallback={onRedirectCallback}>
    <PrismicProvider
      linkResolver={linkResolver}
      externalLinkComponent={(props) => {
        const childProps = { ...props }
        if (childProps.staff) {
          childProps.href = `${childProps.href}#/staff_member/${childProps.staff}`
        } else if (childProps.discipline) {
          childProps.href = `${childProps.href}#/discipline/${childProps.discipline}`
        }
        delete childProps.activeClassName
        delete childProps.staff
        delete childProps.discipline
        childProps.target = props.target || '_blank'
        childProps.rel = props.rel || 'noopener noreferrer'
        return (
          <a data-location='external' {...childProps}>
            {props.children}
          </a>
        )
      }}
      internalLinkComponent={({ href, ...props }) => <Link to={href} {...props} />}>
      <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>{element}</PrismicPreviewProvider>
    </PrismicProvider>
  </Auth0Provider>
)
